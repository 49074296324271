import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Description`}</h4>
    <p>{`The SaveMyBike system constitutes a set of interacting services provided to
citizens to improve the usage of bicycle transport and of the other connected
sustainable transport modes.`}</p>
    <p>{`While working at `}<ExternalLink href="https://www.geosolutionsgroup.com/" mdxType="ExternalLink">{`GeoSolutions`}</ExternalLink>{`
I was part of the team that developed the SaveMyBike portal, REST API and
the backend processing services.`}</p>
    <p>{`The portal is a standard django project that allows both endusers and admins
to act upon their respective bike-related items. `}</p>
    <p>{`Backend services are responsible for post-processing collected waypoint data
uploaded by user's mobile app. These include:`}</p>
    <ul>
      <li parentName="ul">{`Cleaning up uploaded waypoint data`}</li>
      <li parentName="ul">{`Generating proper linestring tracks from individual waypoints`}</li>
      <li parentName="ul">{`Calculation of several indicators related to greenhouse gas emissions and health`}</li>
      <li parentName="ul">{`Updating competition scores and leaderboards`}</li>
    </ul>
    <p>{`These backend services are implemented as a set of Python modules and run on
the AWS platform.`}</p>
    <h4>{`My role`}</h4>
    <p>{`In this project I was the main backend engineer, which means I implemented
most of the backend code. This included:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The portal's backend logic, including business rules, data modelling and django-related stuff`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`REST API's endpoints`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Integration with keycloak in order to leverage authentication with OpenID Connect`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Orchestrating AWS services and ensuring timely feedback supplied to mobile apps`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Business logic for processing uploaded waypoint data. This includes: `}</p>
        <ul parentName="li">
          <li parentName="ul">{`Spatial processing of points into segments and tracks`}</li>
          <li parentName="ul">{`Calculation of various environment and health performance indicators`}</li>
          <li parentName="ul">{`Processing competition data.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      